
import * as Components from "./Lamaria"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "lamaria"
}
